import React from "react";
import { useCart } from "react-use-cart";
import Header from "../components/headers/light";
import Footer from "../components/footers/FiveColumnWithInputForm.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { formatPrice } from "helpers/helpers";
import { Navigate, useNavigate } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import { BsTrash3Fill } from "react-icons/bs";
import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

const Cart = () => {
  // Panggil fungsi dan state yang diperlukan dari useCart
  const { updateItemQuantity, removeItem, emptyCart, cartTotal, items } =
    useCart();
  const totalPrice = cartTotal;
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  const Container = tw.div`relative bg-teal-400 text-gray-700  -mx-8 px-4 py-8 lg:py-12`;
  const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;

  const handleUpdateQuantity = (id, newQuantity) => {
    // Your code here
    updateItemQuantity(id, newQuantity);
  };

  const handleRemoveItem = (id) => {
    // Your code here
    removeItem(id);
  };

  const handleEmptyCart = () => {
    // Your code here
    emptyCart();
  };

  // const calculateTotalPrice = () => {
  //   // Your code here
  // };
  console.log(items);
  const renderCheckOutButton = () => {
    if (user) {
      return (
        <Button
          type="default"
          href={"/checkout"}
          className="bg-white rounded-lg text-black p-2"
        >
          Checkout
        </Button>
      );
    } else {
      return (
        <Button
          type="default"
          href={"/login"}
          className="bg-white rounded-lg text-black p-2"
        >
          Login to Checkout
        </Button>
      );
    }
  };

  return (
    <AnimationRevealPage>
      <Header className="mb-8" />

      <Container>
        <Content>
          <div>
            {items.map((cartItem) => (
              <div
                key={cartItem.id}
                className="flex justify-evenly items-center rounded-lg shadow-lg bg-white gap-5 my-5 p-3"
              >
                <img
                  src={`${process.env.REACT_APP_API_URL}/api/images/${cartItem.images[0]}`}
                  alt="cartItem-img"
                  className="w-64"
                />
                <div className="flex flex-col w-72 items-start border-l border-r px-5 gap-8">
                  <h3 className="text-black text-lg font-semibold">
                    {cartItem.title}
                  </h3>
                  <h3 className="text-black">{formatPrice(cartItem.price)}</h3>
                  <div
                    className="w-8 h-8 rounded-full cursor-pointer border-2"
                    style={{ backgroundColor: `${cartItem.colors}` }}
                  />
                  <div className="flex items-center gap-3">
                    <Button
                      onClick={() =>
                        handleUpdateQuantity(
                          cartItem.id,
                          Math.max(1, cartItem.quantity - 1)
                        )
                      }
                    >
                      -
                    </Button>
                    <p>{cartItem.quantity}</p>
                    <Button
                      onClick={() =>
                        handleUpdateQuantity(
                          cartItem.id,
                          Math.min(cartItem.stock, cartItem.quantity + 1)
                        )
                      }
                    >
                      +
                    </Button>
                  </div>
                </div>
                <div className="flex items-center justify-end gap-5 pr-4 px-5 h-full">
                  <h2 className="text-black text-lg font-semibold">
                    {formatPrice(cartItem.itemTotal)}
                  </h2>
                  <Button onClick={() => handleRemoveItem(cartItem.id)}>
                    <DeleteOutlined />
                  </Button>
                </div>
              </div>
            ))}
            <div className="flex flex-col gap-2">
              <div className="bg-white rounded-lg p-1 shadow-lg">
                <h2 className="text-right text-black font-bold text-lg">
                  Total Order Price: {formatPrice(cartTotal)}
                </h2>
              </div>
              <div className="flex flex-col items-center justify-center gap-4">
                <div className="flex gap-5">
                  {renderCheckOutButton()}
                  <Button
                    style={{ backgroundColor: "white" }}
                    type="default"
                    onClick={handleEmptyCart}
                  >
                    Empty Cart
                  </Button>
                </div>
                <Button
                  style={{ backgroundColor: "white" }}
                  type="default"
                  onClick={() => navigate("/products")}
                >
                  Continue Shopping
                </Button>
              </div>
            </div>
          </div>
        </Content>
      </Container>

      <Footer background="bg-white" />
    </AnimationRevealPage>
  );
};

export default Cart;
